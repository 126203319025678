<template>
    <div :style="{height:(screenWidth>1200?'130px':'1.07rem')}">
        <div class="header header-pc" style="height: 130px;">
            <div class="header-content">
                <div class="header-search">
                    <div class="search-box">
                        <div class="search">
                            <input @keyup.13="search" v-model="content" type="text" placeholder="请输入关键字…">
                            <div @click="search">
                                <img src="../assets/img/search.png" alt="">
                                搜索
                            </div>
                        </div>
                        <ul class="small-navigation">
                            <!-- <li @click="toNewLink(0)">学生成长助手</li>
                            <li @click="toNewLink(1)">学习空间</li>
                            <li @click="toNewLink(2)">校长信箱</li> -->
                            <li v-for="(item,index) of barList" @click="toNewLink(item.type,item)" :key=index>{{ item.label }}</li>
                            <li @click="toNewLink(3)">招贤纳士</li>
                            <li @click="toNewLink(4)">资源中心</li>
                            <!-- <li><a href="openExp:">资源中心</a></li> -->
                        </ul>
                    </div>
                    <ul class="navigation">
                        <li v-for="(item,index) of list" :key="item.title" @click="toNew(index)">
                            <span :class="{active:ind==index}" v-if="index==0">{{item.title}}</span>
                            <el-dropdown v-else @command="handleCommand">
                                <span :class="{active:ind==index}" class="el-dropdown-link">
                                  {{item.title}}
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item class="dropdown" v-for="(ite,i) of item.content" :key="i.text" :command="index+','+i">{{ite.text}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </li>
<!-- <li :class="{active:ind==index}" v-for="(item,index) of list" :key="item.title" @click="toNew(index)">{{item.title}}</li> -->
</ul>
</div>
</div>
</div>
<div class="header header-mobile"  style="height: 1.07rem; display: none;">
    <div class="header-right" @click="openMenu">
        <van-icon v-show="!headerShow" name="wap-nav" />
        <van-icon v-show="headerShow" name="cross" />
    </div>
</div>
<van-popup v-model="headerShow" class="popup-mask header-search" position="top">
    <div class="search-box">
        <div class="search">
            <input @keyup.13="search" v-model="content" type="text" placeholder="请输入关键字…">
            <div @click="search">
                <img src="../assets/img/search.png" alt=""> 搜索
            </div>
        </div>
        <!-- <ul class="mask-middle">
                    <li v-for="(item,index) of list" :key="item.title" @click="toNew(index)">{{item.title}}</li>
                </ul> -->
        <ul class="mask-middle">
            <li v-for="(item,index) of list" :key="item.title">
                <span v-if="index==0" @click="toNew(index,'small')">{{item.title}}</span>
                <van-popover v-else v-model="item.showPopover" trigger="click" :actions="item.content" @select="onSelect">
                    <template #reference>
                                <span>{{item.title}}</span>
                            </template>
</van-popover>
</li>
</ul>
</div>

<ul class="small-navigation">
    <!-- <li @click="toNewLink(0)">学生成长助手</li>
    <li @click="toNewLink(1)">学习空间</li>
    <li @click="toNewLink(2)">校长信箱</li> -->
    <li v-for="(item,index) of barList" @click="toNewLink(item.type,item)" :key=index>{{ item.label }}</li>
    <li @click="toNewLink(3)">招贤纳士</li>
    <li @click="toNewLink(4)">资源中心</li>
    <!-- <li><a href="openExp:">资源中心</a></li> -->
</ul>

</van-popup>
<span id="copyMy" style="display: none;"> {{mailbox}}</span>
<van-dialog v-model="show" style="width:80%;margin: 5px auto 0;">
    <img style="width:80%;display: block;margin: 5px auto 0;" :src="growUrl" />
</van-dialog>
<van-dialog v-model="openShow" style="width:80%;margin: 5px auto 0;">
    <div class="button-title">资源中心</div>
    <ul class="button-style">
        <li>
            <a href="openExp:">
                <img src="../assets/img/ftp.png" alt=""> 打开FTP
            </a>
        </li>
        <li>
            <a href="openFileZilla:">
                <img src="../assets/img/file.png" alt=""> 打开FileZilla
            </a>
        </li>
    </ul>
</van-dialog>
</div>
</template>

<script>
    import {
        baseList
    } from "@/request/api";
    export default {
        name: 'HelloWorld',
        data() {
            return {
                headerShow: false,
                ind: 0,
                content: '',
                // 浏览器宽度
                screenWidth: 0,
                list: [],
                show: false,
                openShow: false,
                growUrl:'',
                studySpace:'',
                mailbox:'',
                barList:[],
                openObj:{}
            }
        },
        created() {
            if (this.headerList.length > 1) this.list = this.headerList;
            else this.$router.replace('/404');
            this.baseList();
        },
        watch: {
            $route(to, from) {
                let type = to.path;
                if (type == '/') this.ind = 0;
                if (type == '/know') this.ind = 1;
                if (type == '/party') this.ind = 2;
                if (type == '/dynamic') this.ind = 3;
                if (type == '/announcements') this.ind = 4;
                if (type == '/moral') this.ind = 5;
                if (type == '/teach') this.ind = 6;
                if (type == '/information') this.ind = 7;
            },
            screenWidth(val, oldVal) {
                if (val != oldVal) {
                    // console.log(val);
                }
            },

        },
        methods: {
            baseList() {
                const _this = this;
                baseList().then(res => {
                    if(res.code==0){
                        console.log(res.data);
                        this.barList=res.data.filter(item => item.enable == "1");
                        for(let item of res.data){
                            if(item.type==='PIC'){
                                // _this.growUrl=item.value;
                            }
                            if(item.type==='ADDRESS'){
                                _this.studySpace=item.value;
                            }
                            if(item.type==='TEXT'){
                                // _this.mailbox=item.value;
                            }
                        }
                    }
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            toNewLink(type,item) {
                this.headerShow = false;
                if (type == 'PIC') {
                    if (this.screenWidth < 1000) {
                        this.show = true;
                    } else {
                        this.studentGrow(item); //学生成长助手
                    }
                }
                if (type == 'ADDRESS') window.open(this.studySpace); //location.href = 'http://www.xaeduyun.cn/'; //绿色校园 http://www.sdjyxx.cn:8000/
                if (type == 'TEXT') {
                    this.mailbox=item.value;
                    if (this.screenWidth < 1000) {
                        this.minHeader(item);
                    } else {
                        this.headmaster(item); //校长信箱
                    }
                }
                if (type == 3) {
                    this.$router.push({
                        path: `/career`,
                    }); // location.href = 'http://www.sdjyxx.cn/' + '最新版---陕西师范大学嘉园小学应聘人员登记表2020版.docx';
                }
                if (type == 4) {
                    if (this.screenWidth < 1000) {
                        this.openShow = true;
                    } else {
                        let img1 = require('../assets/img/ftp.png');
                        let img2 = require('../assets/img/file.png');
                        this.$alert(`<ul class="button-style" style="display:flex;width: 60%;justify-content: space-between;">
                        <li style="margin-right:5px;"><a href="openExp:" style="color:#409eff;background-color:#ecf5ff;width:120px; border:1px solid #b3d8ff;
        font-size: 14px;display: flex;justify-content: center;height:35px;
    align-items: center;border-radius: 4px;">
        <img style="width:18px;height:18px;margin-right:5px;" src="${img1}" alt="">打开FTP</a></li>
                        <li><a href="openFileZilla:" style="color:#f56c6c;background-color: #fef0f0;width:120px; border:1px solid #fbc4c4;box-sizing:border-box;
        font-size: 14px;display: flex;justify-content: center;height:35px;
    align-items: center;border-radius: 4px;">
        <img style="width:18px;height:18px;" src="${img2}" alt="">打开FileZilla</a></li>
                        </ul>`, '资源中心', {
                            confirmButtonText: '确定',
                            dangerouslyUseHTMLString: true
                        });
                    }
                }
            },
            studentGrow(item) {
                // let url = 'https://www.sdjyxx.cn/jyhome/profile//202302/20230218111017A001.png';
                let url=item.value;
                this.$alert(`<img style="width:50%;display:block;margin:auto;" src="${url}" />`, item.label, {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true
                });
            },
            minHeader(item) {
                this.$dialog.alert({
                    title: item.label,
                    message: item.label + ":" + item.value,
                    confirmButtonText: '复制',
                }).then(() => {
                    const url = item.value;
                    let oInput = document.createElement('input')
                    oInput.value = url
                    document.body.appendChild(oInput)
                    oInput.select() // 选择对象;
                    document.execCommand('Copy') // 执行浏览器复制命令
                    oInput.parentNode.removeChild(oInput);
                    this.$toast('复制成功！')
                });
            },
            headmaster(item) {
                this.$confirm(item.label+":"+item.value, item.label, {
                    confirmButtonText: '复制',
                    cancelButtonText: '取消',
                    // type: 'warning'
                }).then(() => {
                    const url = item.value;
                    let oInput = document.createElement('input')
                    oInput.value = url
                    document.body.appendChild(oInput)
                    oInput.select() // 选择对象;
                    document.execCommand('Copy') // 执行浏览器复制命令
                    oInput.parentNode.removeChild(oInput);
                    this.$message({
                        type: 'success',
                        message: '复制成功!'
                    });
                }).catch(() => {

                });
            },
            // pc端
            handleCommand(command) {
                let arr = command.split(',');
                this.ind = arr[0];
                this.toNewPage(parseInt(arr[0]), parseInt(arr[1]) + 1);
            },
            onSelect(action) {
                this.headerShow = false;
                let arr = this.getList(action);
                this.toNewPage(parseInt(arr[0]), parseInt(arr[1]) + 1);
            },
            getList(action) {
                console.log(action);
                let item, ite;
                for (let i = 0; i < this.list.length; i++) {
                    item = this.list[i];
                    for (let j = 0; j < item.content.length; j++) {
                        ite = item.content[j];
                        if (ite.title == action.title) {
                            return [i, j];
                        }
                    }
                }
            },
            setSize: function() {
                if (this.screenWidth <= 1200) {
                    // this.bannerHeight = 400;
                } else {
                    // 通过浏览器宽度(图片宽度)计算高度
                    // this.bannerHeight = 660 / 1920 * this.screenWidth;
                }
            },
            toNew(index, type) {
                this.content = '';
                if (type == 'small') {
                    this.headerShow = false;
                    this.$router.push('/');
                }
                if (this.ind == index && index != 0) return
                else {
                    this.ind = index;
                    sessionStorage.setItem('pcInd', this.ind);
                }
                this.toNewPage(this.ind);
            },
            search() {
                if (this.content) {
                    this.$router.push({
                        path: `/search`,
                        query: {
                            content: this.content,
                        }
                    })
                } else {
                    this.$message('您搜索的内容不能为空！');
                }
            },
            toNewPage(ind, id) {
                console.log(ind + '----' + id);
                switch (ind) {
                    case 0:
                        this.$router.push('/');
                        break;
                    case 1:
                        if (id) {
                            this.$router.push({
                                path: `/know`,
                                query: {
                                    id: id,
                                }
                            })
                        } else this.$router.push('/know');
                        break;
                    case 2:
                        if (id) {
                            this.$router.push({
                                path: `/party`,
                                query: {
                                    id: id,
                                }
                            })
                        } else this.$router.push('/party');
                        break;
                    case 3:
                        if (id) {
                            this.$router.push({
                                path: `/dynamic`,
                                query: {
                                    id: id,
                                }
                            })
                        } else this.$router.push('/dynamic');
                        break;
                    case 4:
                        if (id) {
                            this.$router.push({
                                path: `/announcements`,
                                query: {
                                    id: id,
                                }
                            })
                        } else this.$router.push('/announcements');
                        break;
                    case 5:
                        if (id) {
                            this.$router.push({
                                path: `/moral`,
                                query: {
                                    id: id,
                                }
                            })
                        } else this.$router.push('/moral');
                        break;
                    case 6:
                        if (id) {
                            this.$router.push({
                                path: `/teach`,
                                query: {
                                    id: id,
                                }
                            })
                        } else this.$router.push('/teach');
                        break;
                    case 7:
                        if (id) {
                            this.$router.push({
                                path: `/information`,
                                query: {
                                    id: id,
                                }
                            })
                        } else this.$router.push('/information');
                        break;
                    default:
                        break;
                }
            },
            openMenu() {
                this.headerShow = !this.headerShow;
            }
        },
        mounted() {
            // 首次加载时,需要调用一次
            this.screenWidth = window.innerWidth;
            this.setSize();
            // 窗口大小发生改变时,调用一次
            window.onresize = () => {
                this.screenWidth = window.innerWidth;
                this.setSize();
            };
            if (sessionStorage.getItem('pcInd')) {
                this.ind = sessionStorage.getItem('pcInd');
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    /*  500       1024       1280          1366      1440       1680       1920  */
    /* 顶部导航背景图 */
    
    .header {
        background: url(../assets/img/header.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        position: fixed;
        z-index: 999;
        top: 0;
    }
    
    .header-content {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        width: 80%;
        left: 50%;
        transform: translate(-50%, 0);
        /* 第一行搜索框显示 */
        .header-search {
            width: 80%;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-around;
            height: 120px;
            .search-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 80%;
                .search {
                    width: 320px;
                    height: 32px;
                    background: rgba(255, 255, 255, .2);
                    border-radius: 16px;
                    position: relative;
                    div {
                        position: absolute;
                        width: 64px;
                        height: 32px;
                        background: #820000;
                        border-radius: 16px;
                        top: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        font-size: 12px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        padding: 0 11px;
                        box-sizing: border-box;
                        cursor: pointer;
                        img {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
            }
        }
    }
    /* 顶部导航 */
    
    .small-navigation li {
        position: relative;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 12px;
        max-width: 100px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: '';
            display: block;
            width: 1px;
            height: 12px;
            background: #FFFFFF;
            opacity: 0.5;
        }
        &:last-child::after {
            opacity: 0;
        }
    }
    
    .small-navigation li a {
        color: #fff;
    }
    
    .navigation {
        width: 90%;
        justify-content: space-between;
        li {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;
            span {
                opacity: .8;
            }
            span.active {
                font-size: 20px;
                font-weight: bold;
                opacity: 1;
            }
        }
    }
    /* 通用 */
    
    ul {
        display: flex;
        justify-content: flex-end;
    }
    
    input {
        width: 250px;
        height: 32px;
        float: left;
        padding-left: 16px;
    }
    
    input::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, .5);
    }
    
    input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, .5);
    }
    
    input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, .5);
    }
    
    input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, .5);
    }
    /* 底部导航 */
    
    .el-dropdown-link {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        opacity: .8;
        cursor: pointer;
        display: block;
    }
    
    .el-dropdown-menu {
        display: block;
    }
    
    .el-icon-arrow-down {
        font-size: 12px;
        display: block;
    }
    /* 小于1024 */
    
    @media screen and (max-width: 1400px) {
        .header-content .header-search .search-box {
            width: 80%;
        }
    }
    
    @media screen and (max-width: 1200px) {
        .header-pc {
            display: none !important;
        }
        .header-mobile {
            display: block !important;
        }
        .header {
            z-index: 9999;
            display: flex;
            align-items: center;
        }
        .header-right {
            position: absolute;
            right: .4rem;
            color: #fff;
            font-size: .5rem;
            top: 50%;
            transform: translate(10px, -50%);
        }
        /* 弹窗 */
        .popup-mask {
            top: 1.07rem;
        }
        .search {
            width: 7.33rem;
            height: 0.85rem;
            border-radius: 0.43rem;
            border: 0.01rem solid #E2E2E2;
            position: relative;
            img {
                margin-right: .13rem;
                width: .3rem;
            }
            div {
                width: 1.71rem;
                height: 0.85rem;
                background: #C5181F;
                border-radius: 0.43rem;
                position: absolute;
                right: 0;
                font-size: 0.32rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .mask-middle {
            flex-wrap: wrap;
            li {
                width: 3.33rem;
                height: 0.96rem;
                border-radius: 0.48rem;
                border: 0.01rem solid #E2E2E2;
                font-size: 0.43rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #050000;
                line-height: 0.96rem;
                text-align: center;
            }
        }
        .search-box {
            width: 7.33rem;
            margin: 1.07rem auto 0;
            .mask-middle {
                margin-top: .4rem;
                justify-content: space-between;
                li {
                    margin-bottom: .27rem;
                }
            }
        }
        .popup-mask .small-navigation {
            width: 100%;
            height: 1.07rem;
            background: #C5181F;
            color: #FFFFFF;
            align-items: center;
            justify-content: center;
            li {
                font-size: 0.32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    
    .dropdown:hover {
        background-color: rgba(197, 24, 31, .1) !important;
        color: rgba(197, 24, 31, 1) !important;
    }
    
    input {
        font-size: 16px;
    }
    
    .button-title {
        font-size: 18px;
        text-align: center;
        padding-top: 15px;
        font-weight: bold;
    }
    
    ul.button-style {
        display: flex;
        width: 60%;
        margin: 20px auto;
        justify-content: space-between;
    }
    
    ul.button-style li a {
        color: #409eff;
        background-color: #ecf5ff;
        width: 120px;
        border: 1px solid #b3d8ff;
        font-size: 14px;
        display: flex;
        justify-content: center;
        height: 35px;
        align-items: center;
        border-radius: 4px;
    }
    
    ul.button-style li:last-child a {
        color: #f56c6c;
        background-color: #fef0f0;
        border: 1px solid #fbc4c4;
    }
    
    ul.button-style li img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }
</style>
// export const baseUrl = 'http://192.168.1.191/jyxx' // 测试
// export const baseUrl = 'http://192.168.1.48' // 测试

// export const baseUrl = 'http://39.105.71.11:7003/jyxx' // 居家测试
export const baseUrl = 'https://www.sdjyxx.cn/jyhome' // 嘉园小学



function header() {
    var headerList1 = [{
        title: '首页',
        content: []
    }]
    $.ajax({
        type: "get",
        url: baseUrl + '/app/indexTree',
        async: false,
        error: function(request) {
            console.log(request);
        },
        success: (res) => {
            if (res.data) headerList1 = headerList1.concat(res.data);
        }
    })
    return headerList1
}

let arry = header();
console.log(arry);
for (let item of arry) {
    if (item.content) {
        for (let ite of item.content)
            ite.text = ite.title;
    }
}
export const headerList = arry;
// export const headerList = [{
//     title: '首页',
//     content: []
// }, {
//     title: '认识嘉小',
//     showPopover: false,
//     content: [{
//         id: 1,
//         ind: 1,
//         text: '学校简介'
//     }, {
//         id: 2,
//         ind: 1,
//         text: '领导班子'
//     }, {
//         id: 3,
//         ind: 1,
//         text: '部门机构'
//     }, {
//         id: 4,
//         ind: 1,
//         text: '学校荣誉'
//     }, {
//         id: 5,
//         ind: 1,
//         text: '美丽校园'
//     }]
// }, {
//     title: '党支部',
//     content: [{
//         id: 1,
//         ind: 2,
//         text: '党建工作'
//     }, {
//         id: 2,
//         ind: 2,
//         text: '党员活动'
//     }]
// }, {
//     title: '校园动态',
//     content: [{
//         id: 1,
//         ind: 3,
//         text: '校园动态'
//     }, {
//         id: 2,
//         ind: 3,
//         text: '教育新闻'
//     }]
// }, {
//     title: '通知公告',
//     content: [{
//         id: 1,
//         ind: 4,
//         text: '通知公告'
//     }, {
//         id: 2,
//         ind: 4,
//         text: '工作公告'
//     }, {
//         id: 3,
//         ind: 4,
//         text: '每周食谱'
//     }]
// }, {
//     title: '德育阵地',
//     content: [{
//         id: 1,
//         ind: 5,
//         text: '少队活动'
//     }, {
//         id: 2,
//         ind: 5,
//         text: '社团风采'
//     }, {
//         id: 3,
//         ind: 5,
//         text: '家校共育'
//     }, {
//         id: 4,
//         ind: 5,
//         text: '学生活动'
//     }]
// }, {
//     title: '教学科研',
//     content: [{
//         id: 1,
//         ind: 6,
//         text: '教师培训'
//     }, {
//         id: 2,
//         ind: 6,
//         text: '嘉小课堂'
//     }, {
//         id: 3,
//         ind: 6,
//         text: '学科活动'
//     }, {
//         id: 4,
//         ind: 6,
//         text: '教师风采'
//     }, {
//         id: 5,
//         ind: 6,
//         text: '成果展示'
//     }]
// }, {
//     title: '信息公开',
//     content: [{
//         id: 1,
//         ind: 7,
//         text: '办事指南'
//     }, {
//         id: 2,
//         ind: 7,
//         text: '家长留言'
//     }, {
//         id: 3,
//         ind: 7,
//         text: '学期校历'
//     }, {
//         id: 4,
//         ind: 7,
//         text: '教育法规'
//     }]
// }]
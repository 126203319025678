import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(ElementUI, { locale })

import 'vant/lib/index.css';
import { Icon, Popup, Popover, Button, Swipe, SwipeItem, Dialog } from 'vant';
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Dialog);



import { headerList } from '../public/js/http'
Vue.prototype.headerList = headerList

// import clipboard from 'clipboard';
// Vue.prototype.clipboard = clipboard;
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);
Vue.use(VueLazyload, {
    error: require('../src/assets/img/img-error.png'), // 此处是图片加载失败时候 显示的图片
    loading: require('../src/assets/img/img-loading.png'), // 此处是图片加载中 显示的图片
    attempt: 2, // 加载一屏图片
    preLoad: 5, // 失败尝试次数
    listenEvents: ['scroll']
});

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
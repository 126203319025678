<template>
    <div class="page">
        <img v-show="isShow" class="purpose" src="../assets/img/footer-middle.png" alt="">
        <div class="footer-bottom">
            <div class="footer-main">
                <img class="footer-logo" src="../assets/img/footer-logo.png" alt="">
                <ul class="footer-font">
                    <li>高陵嘉园小学</li>
                    <li></li>
                    <li>学校地址：西安市高陵区崇皇街道办高茹路九横路十字西南</li>
                    <li>学校电话：029-86965566</li>
                </ul>
                <ul class="footer-img">
                    <li v-for="(item,index) of iconList" :key="item.img" @mouseenter="enter(index)" @mouseleave="leave">
                        <div v-if="ind==index">
                            <img :src="item.code" alt="">
                        </div>
                        <img :src="item.img" alt="">
                    </li>
                </ul>
            </div>
        </div>
        <div class="technical-support">
            <ul>
                <li>版权所有：高陵嘉园小学</li>
                <li>陕ICP备347580586号</li>
                <li>技术支持：陕西中海华晟信息技术有限公司</li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                isShow: true,
                ind: -1,
                iconList: [{
                    img: require('../assets/img/footer-icon01.png'),
                    code: require('../assets/img/footer-icon001.png')
                }, {
                    img: require('../assets/img/footer-icon02.png'),
                    code: require('../assets/img/footer-icon002.png')
                }, {
                    img: require('../assets/img/footer-icon03.png'),
                    code: require('../assets/img/footer-icon003.png')
                }, {
                    img: require('../assets/img/footer-icon04.png'),
                    code: require('../assets/img/footer-icon004.png')
                }]
            }
        },
        watch: {
            $route(to, from) {
                // console.log(from.path); //从哪来
                // console.log(to.path); //到哪去
                if (to.path == '/') {
                    this.isShow = true
                } else {
                    this.isShow = false
                }
            }
        },
        methods: {
            enter(i) {
                this.ind = i;
            },
            leave() {
                this.ind = -1;
            }

        }

    }
</script>
<style scoped lang="scss">
    /* 积德行善 */
    
    .page img.purpose {
        width: 80%;
        max-width: 800px;
        display: block;
        margin: 100px auto;
    }
    /* 底部背景图 */
    
    .footer-bottom {
        background: url(../assets/img/bottom.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 200px;
        position: relative;
    }
    /* 底部信息 */
    
    .footer-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        max-width: 1200px;
        height: 200px;
        margin: auto;
        /* 底部logo */
        .footer-logo {
            width: 214px;
            height: auto;
        }
        /* 中间文字 */
        .footer-font {
            text-align: left;
            color: #FFFFFF;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            li:first-child {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
            }
            li:nth-child(2) {
                width: 322px;
                height: 2px;
                background: #FFFFFF;
                margin: 20px 0 20px 0;
            }
            li:nth-child(3) {
                margin-bottom: 10px;
            }
        }
        /* 微信/抖音等平台logo */
        .footer-img {
            display: flex;
            img {
                width: 54px;
                height: auto;
                margin-right: 20px;
            }
            li:last-child img {
                margin-right: 0;
            }
        }
    }
    
    .footer-img li {
        position: relative;
        div {
            position: absolute;
            bottom: 60px;
            left: 50%;
            transform: translate(-50%, 0);
            background: rgba(0, 0, 0, .3);
            padding: 10px;
            box-sizing: border-box;
            img {
                width: 130px !important;
                height: 130px !important;
                margin-right: 0 !important;
            }
            /* display: none; */
        }
    }
    /* 技术支持 */
    
    .technical-support {
        width: 100%;
        height: 36px;
        background: #560000;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 50%;
            max-width: 800px;
            li {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    
    @media screen and (max-width: 1200px) {
        /* 积德行善 */
        .page img.purpose {
            width: 9.06rem;
            margin: .97rem auto .4rem;
        }
        /* 底部背景图 */
        .footer-bottom {
            width: 10rem;
            height: auto;
            background-size: 100% 100%;
            box-sizing: border-box;
        }
        /* 底部信息 */
        .footer-main {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-top: .72rem;
            padding-bottom: .72rem;
            height: auto;
            .footer-logo {
                height: .48rem;
                width: auto;
                margin-bottom: .47rem;
            }
        }
        .footer-font {
            li:first-child {
                font-size: 0.37rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
            }
            li:nth-child(2) {
                width: 4.29rem;
                height: 0.03rem;
                background: #FFFFFF;
                margin: .27rem 0;
            }
            li:nth-child(3),
            li:nth-child(4) {
                font-size: 0.29rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
        .footer-img {
            margin-top: .4rem;
            img {
                width: .85rem;
            }
        }
        .technical-support {
            height: auto;
            padding: .27rem 0;
            box-sizing: border-box;
            ul {
                width: 80%;
                flex-direction: column;
                align-items: flex-start;
                li {
                    font-size: 0.32rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-bottom: .13rem;
                }
            }
        }
    }
</style>
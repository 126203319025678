<template>
  <div id="app">
    <div v-if="tzgg">
        <router-view/>
    </div>
    <div v-else>
        <div class="logo" @click="toHome">
            <img src="./assets/img/logo.png" alt="">
        </div>
        <Header></Header>
        <router-view/>
        <Footer></Footer> 
    </div>
    
  </div>
</template>
<script>
    import Header from './components/Header.vue'
    import Footer from './components/Footer.vue'
    export default {
        components: {
            Header,
            Footer
        },
        data() {
            return {
                tzgg: false
            }
        },
        created() {
            window.scroll(0, 0);
        },
        mounted() {
            window.addEventListener('offline', () => {
                // 网络由正常常到异常时触发
                sessionStorage.locationUrl = window.location.href;
                this.$router.replace('/404')
            });
            window.addEventListener('online', () => {
                window.location.href = sessionStorage.locationUrl
            });
        },
        watch: {
            $route(to) {
                let type = to.path;
                if (type == '/tzgg' || type == '/404') this.tzgg = true;
                else this.tzgg = false;
            },
        },
        methods: {
            toHome() {
                this.$router.push('/');
            }
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    #nav {
        padding: 30px;
        a {
            font-weight: bold;
            color: #2c3e50;
            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
    
    .logo {
        width: 180px;
        height: 180px;
        background: #ffffff;
        border-radius: 0 0 50% 50%;
        box-shadow: 0rem 0.03rem 0.2rem 0rem rgba(130, 0, 0, 0.2);
        position: fixed;
        z-index: 99999;
        left: 10%;
        top: 0;
        padding: 15px;
        box-sizing: border-box;
        img {
            width: 100%;
            height: auto;
        }
    }
    
    @media screen and (max-width: 1200px) {
        .logo {
            width: 1.44rem;
            height: 1.44rem;
            left: 1.07rem;
            background: #FFFFFF;
            box-shadow: 0rem 0.03rem 0.2rem 0rem rgba(130, 0, 0, 0.2);
            border-radius: 0rem 0rem 1.33rem 1.33rem;
            padding: .13rem;
            box-sizing: border-box;
            img {
                width: 100%;
                /* height: 1.44rem; */
            }
        }
    }
</style>
<template>
    <div class="page">
        <Swiper></Swiper>
        <div class="pc-style">
            <!-- 嘉园动态 -->
            <div class="big-title">
                <img src="../assets/img/news-title.png" alt="">
                <div class="more" @click="toNewPage(1)">查看更多<img src="../assets/img/to-more.png" alt=""></div>
            </div>
            <div class="news-content">
                <img class="bck" src="../assets/img/news-content.png" alt="">
                <div class="news-box">
                    <div class="left">
                        <div v-for="(item,index) of indexJYDTList" :key="index">
                            <template v-if="index==0">
                                <el-carousel height="360px">
                                    <el-carousel-item v-for="(ite) of item.path" :key="ite">
                                        <div class="cover-img" v-if="ite" @click="articleDetail(item,1)">
                                            <img v-lazy="ite" alt="">
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                                <div class="news-con-box" @click="articleDetail(item,1)">
                                    <div class="content-title">{{item.title}}</div>
                                    <div class="time" v-if="item.createTime"><span>{{item.createTime.substring(0,10)}}</span> <img src="../assets/img/to-more.png" alt=""></div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="right">
                        <ul>
                            <li class="small-img" v-if="index==1" @click="articleDetail(item,1)" v-for="(item,index) of indexJYDTList" :key="index">
                                <div class="cover-img">
                                    <img v-if="item.path.includes(',')" :src="item.path.split(',')" alt="">
                                    <img v-else :src="item.path" alt="">
                                </div>
                                <div class="news-con-box">
                                    <div class="content-title">{{item.title}}</div>
                                    <div class="time" v-if="item.createTime">{{item.createTime.substring(0,10)}} <img src="../assets/img/to-more-white.png" alt=""></div>
                                </div>
                            </li>
                            <li class="news-con-box" v-if="index==3||index==5" @click="articleDetail(item,1)" v-for="(item,index) of indexJYDTList" :key="item.id">
                                <div class="content-title">{{item.title}}</div>
                                <div class="time" v-if="item.createTime">{{item.createTime.substring(0,10)}} <img src="../assets/img/to-more.png" alt=""></div>
                            </li>
                        </ul>
                        <ul>
                            <li class="small-img" v-if="index==2" @click="articleDetail(item,1)" v-for="(item,index) of indexJYDTList" :key="item.id">
                                <div class="cover-img">
                                    <img v-if="item.path.includes(',')" :src="item.path.split(',')" alt="">
                                    <img v-else :src="item.path" alt="">
                                </div>
                                <div class="news-con-box">
                                    <div class="content-title">{{item.title}}</div>
                                    <div class="time" v-if="item.createTime">{{item.createTime.substring(0,10)}} <img src="../assets/img/to-more-white.png" alt=""></div>
                                </div>
                            </li>
                            <li class="news-con-box" v-if="index==4||index==6" @click="articleDetail(item,1)" v-for="(item,index) of indexJYDTList" :key="item.id">
                                <div class="content-title">{{item.title}}</div>
                                <div class="time" v-if="item.createTime">{{item.createTime.substring(0,10)}} <img src="../assets/img/to-more.png" alt=""></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 通知公告 -->
            <div class="announcement-box">
                <div class="big-title">
                    <img src="../assets/img/school-news.png" alt="">
                    <div class="more" @click="toNewPage(2)">查看更多<img src="../assets/img/to-more-white.png" alt=""></div>
                </div>
                <div class="announcement-main">
                    <div class="announcement-left">
                        <ul>
                            <li @click="articleDetail(item,2)" v-for="(item) of indexTZGGList" :key="item.id">
                                <div class="date-box" v-if="item.createTime">
                                    <p>{{item.createTime.substring(5,11)}}</p>
                                    <span>{{item.createTime.substring(0,4)}}</span>
                                </div>
                                <p>{{item.title}}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="announcement-right block" style="height: auto;">
                        <el-carousel height="480px" :autoplay="flag" @mouseleave.native="delHandleMouseleave" @mouseenter.native="delHandleMouseEnter">
                            <el-carousel-item class="foot-table" v-for="(item,index) of footTable" :key="index">
                                <!-- 本周工作 -->
                                <div class="tzgg-box" v-if="index==0" @click="toNewDetail(footTable[index].id,2)">
                                    <table class="fir-table">
                                        <thead>
                                            <th>星期</th>
                                            <th>时间</th>
                                            <th>内容</th>
                                            <th>地点</th>
                                            <th>负责人/部门</th>
                                        </thead>
                                        <tbody v-if="footTable[index]&&footTable[index].workArrangesVos">
                                            <tr style="padding: 5px 0;" v-for="(ite,ind) of footTable[index].workArrangesVos" :key="ind">
                                                <td>
                                                    <!-- <p :title="ite.work" v-for="(ele,i) of ite.work" :key="i">{{ele}}</p> -->
                                                    <p :title="ite.work">{{ite.work}}</p>
                                                </td>
                                                <td>
                                                    <p v-for="(ele,i) of ite.time" :key="i">
                                                        <span v-if="ele.includes('：')">
                                                            {{(ele.split('：')[0].length==1?'0'+ele.split('：')[0]:ele.split('：')[0])+'：'+(ele.split('：')[1].length==1?'0'+ele.split('：')[1]:ele.split('：')[1])}}
                                                        </span>
                                                        <span v-if="ele.includes(':')">
                                                            {{(ele.split(':')[0].length==1?'0'+ele.split(':')[0]:ele.split(':')[0])+':'+(ele.split(':')[1].length==1?'0'+ele.split(':')[1]:ele.split(':')[1])}}
                                                        </span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p :title="ele" v-for="(ele,i) of ite.details" :key="i">{{ele.length>10?ele.substring(0,10)+'...':ele}}</p>
                                                </td>
                                                <td>
                                                    <p v-for="(ele,i) of ite.place" :key="i">{{ele}}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p v-for="(ele,i) of ite.deptName" :key="i">{{ele}}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- 每周食谱 -->
                                <div v-else @click="toNewDetail(footTable[index].id,3)">
                                    <div class="h3">
                                        <!-- <h3>{{footTable[index].title}}</h3> -->
                                        <ul>
                                            <li>本周主推：<span>{{footTable[index].pushWeek?footTable[index].pushWeek:'-'}}</span></li>
                                            <li>陪餐领导：<span>{{footTable[index].leaderWeek?footTable[index].leaderWeek:'-'}}</span></li>
                                            <li>日期：<span>{{footTable[index].date?footTable[index].date:'-'}}</span></li>
                                        </ul>
                                    </div>
                                    <div class="tzgg-box recipe-block">
                                        <table class="sec-table" style="height:60%">
                                            <thead>
                                                <th>星期</th>
                                                <th>早餐食谱</th>
                                                <th>午餐食谱</th>
                                                <th>午点</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(ite,ind) of footTable[index].weeklyRecipeVos" :key="ind">
                                                    <td>{{ite.week}}</td>
                                                    <td>
                                                        <p v-for="(ele,i) of ite.name" :key="i">{{ele}}</p>
                                                    </td>
                                                    <td>
                                                        <p style="width:100%;" v-for="(ele,i) of ite.food" :key="i">{{ele}}</p>
                                                    </td>
                                                    <td>
                                                        <p v-for="(ele,i) of ite.soup" :key="i">{{ele}}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <img v-if="index==0" style="width:100%" src="../assets/img/work.png" alt="">
                                <img v-else style="width:100%" src="../assets/img/foodList.png" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
            <!-- 学生活动 -->
            <div class="big-title">
                <img src="../assets/img/student-activite.png" alt="">
                <div class="more" @click="toNewPage(3)">查看更多<img src="../assets/img/to-more.png" alt=""></div>
            </div>
            <div class="student-content">
                <div class="student-main" @click="articleDetail(item,3)" v-for="(item,index) of studentList" :key="item.id">
                    <ul v-if="index%2==0">
                        <li class="cover-img" v-if="item.path.includes(',')"><img v-lazy="item.path.split(',')[0]" alt=""></li>
                        <li class="cover-img" v-else><img v-lazy="item.path" alt=""></li>
                        <li class="font-con">
                            <div class="title">{{item.title}}</div>
                            <div class="desc">
                                <div class="subtitle" v-if="item.subtitle">{{item.subtitle}}</div>
                                <div class="summary" v-if="item.summary">{{item.summary}}</div>
                            </div>
                            <ul>
                                <li># {{item.author&&item.author!='undefined'?item.author:'来源'}}</li>
                                <li v-if="item.createTime">{{item.createTime.substring(0,10)}}</li>
                            </ul>
                        </li>
                    </ul>
                    <ul v-else>
                        <li class="font-con">
                            <div class="title">{{item.title}}</div>
                            <div class="desc">
                                <div class="subtitle" v-if="item.subtitle">{{item.subtitle}}</div>
                                <div class="summary" v-if="item.summary">{{item.summary}}</div>
                            </div>
                            <ul>
                                <li># {{item.author&&item.author!='undefined'?item.author:'来源'}}</li>
                                <li v-if="item.createTime">{{item.createTime.substring(0,10)}}</li>
                            </ul>
                        </li>
                        <li class="cover-img" v-if="item.path.includes(',')"><img v-lazy="item.path.split(',')[0]" alt=""></li>
                        <li class="cover-img" v-else><img v-lazy="item.path" alt=""></li>
                    </ul>
                </div>
            </div>
            <!-- 教育教学 -->
            <div class="big-title">
                <img src="../assets/img/school.png" alt="">
                <div class="more" @click="toNewPage(4)">查看更多<img src="../assets/img/to-more.png" alt=""></div>
            </div>
            <div class="educate-con">
                <div class="block">
                    <el-carousel height="540px" :interval="3000" width="100%" @change="handleCurrentChange">
                        <el-carousel-item v-for="(item,index) of educateList"  :key="index">
                            <div class="carousel-box" v-if="item.path">
                                <img v-if="item.path&&item.path.includes(',')" v-lazy="item.path.split(',')[0]" alt="">
                                <img v-else v-lazy="item.path" alt="">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="block-box">
                    <ul>
                        <li :class="{ 'active': num == index }" @click="articleDetail(item,4)" v-for="(item,index) of educateList" :key="item.id">
                            <div class="small-mark" v-if="item.createTime">
                                <p>{{item.createTime.substring(8,10)}}</p>
                                <span>{{item.createTime.substring(0,7)}}</span>
                            </div>
                            <div class="right-desc">
                                <div class="embellish"><i></i><i></i><i></i></div>
                                <p>{{item.title}}</p>
                                <span v-html="item.content"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mobile-style">
            <index :indexJYDTList="indexJYDTList" :jydtList="jydtList" :indexTZGGList="indexTZGGList" :footTable="footTable" :studentList="studentList" :educateList="educateList"></index>
        </div>
    </div>
</template>
<script>
    import Swiper from '../components/Swiper.vue'
    import Index from '../components/Index.vue'
    import {
        indexJYDTGet,
        indexTZGGGet,
        indexORTHERGet,
        indexStudyGet,
        indexCarousel,
        workArrange,
        weeklyRecipe
    } from "@/request/api";
    export default {
        components: {
            Swiper,
            Index
        },
        data() {
            return {
                num: 0,
                indexCarouselList: [],
                // 嘉园动态
                jydtList: [],
                indexJYDTList: [],
                // 通知公告
                indexTZGGList: [{
                    createTime: '2022-12-09',
                    title: '关于疫情期间学生在家上网课的学习安排安排'
                }],
                footTable: [],
                // 学生活动
                studentList: [{
                    id: 1,
                    path: require('../assets/img/student-c01.png'),
                    title: '“不忘初心、扬帆起航”综合组…',
                    content: ' 为了更好的落实本学期各项工作，做到行有目标，做有规划，9月25日上午，综合组全体教师围绕传达学校会议精神、校队建设与发展、综合学科全员德育、新…',
                    source: '三年级三班',
                    createTime: '2022.9.25'
                }, {
                    id: 2,
                    path: require('../assets/img/student-c01.png'),
                    title: '雅言雅行，扮靓人生',
                    content: '有效培养学生语言及行为习惯，成就精彩人生！',
                    source: '三年级三班',
                    createTime: '2022.10.15'
                }, {
                    id: 3,
                    path: require('../assets/img/student-c01.png'),
                    title: '动植物标本欣赏',
                    content: '欣赏精美的动物标本反映了人们对大自然之美的追求和敬畏之心。',
                    source: '三年级三班',
                    createTime: '2022.10.28'
                }, {
                    id: 4,
                    path: require('../assets/img/student-c01.png'),
                    title: '名师送教到高陵 示范引领助成长',
                    content: '为充分发挥示范引领和辐射带动作用，应高陵区教育局邀请，10月24日，高陵嘉园小学六位老师，精心准备了四节示范课和两个专题讲座，来到高陵区…',
                    source: '三年级三班',
                    createTime: '2022.10.24'
                }],
                // 教育教学
                educateList: [{
                    id: 1,
                    createTime: '2022.10.01',
                    path: require('../assets/img/education-c01.png'),
                    title: '致敬共和国，歌颂新时代',
                    desc: '“五星红旗迎风飘扬，胜利歌声多么响亮，歌唱我们亲爱的祖国，从今走向繁荣富强......”9月30日早上，高陵嘉园小学全校师生齐聚操场，高举国旗，伴随着《歌唱祖国》的旋律，同…'
                }, {
                    id: 2,
                    createTime: '2022.09.28',
                    path: require('../assets/img/education-c01.png'),
                    title: '盼望着 盼望着 潇湘小记者终于开课啦！',
                    desc: '2022年9月28日，高陵嘉园小学301教室，由潇湘小记者团队资深老师胡琨彬，为这个学期的首批潇湘小记者开启了新学期的第一课。'
                }, {
                    id: 3,
                    createTime: '2022.09.16',
                    path: require('../assets/img/education-c01.png'),
                    title: '科技筑梦，创新成长——第三十一届科技节系列活动',
                    desc: '扬起科技风帆，体会科学魅力。9月16日，中南大学第一附属小学变身为一片欢乐的海洋——第三十一届科技节“科技筑梦，创新成长”隆重举行。作为国家科学课程的重要拓展，本次科技节重…'
                }, {
                    id: 4,
                    createTime: '2022.09.05',
                    path: require('../assets/img/education-c01.png'),
                    title: '博士课堂之数学趣识开讲啦',
                    desc: '刘教授以一个神奇的数学魔术开启了这堂课的精彩序幕。一说到要变魔术，孩子们两眼放光。刘教授让孩子们从52张扑克中随意抽取5张；再选择一张交给一个同学保管，另外4张按一定的规…'
                }],
                flag: true
            }
        },
        mounted() {
            this.indexJYDTGet();
            this.indexTZGGGet();
            this.indexORTHERGet();
            this.indexStudyGet();
            this.indexCarousel();
            this.workArrange();
            this.weeklyRecipe();
        },
        methods: {
            delHandleMouseleave() {
                this.$nextTick(() => {
                    this.flag = true;
                })
            },
            delHandleMouseEnter() {
                this.$nextTick(() => {
                    this.flag = false;
                })
            },
            workArrange() {
                const _this = this;
                workArrange().then(res => {
                    _this.footTable[0] = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            weeklyRecipe() {
                const _this = this;
                weeklyRecipe().then(res => {
                    _this.footTable[1] = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 校园动态 轮播
            indexCarousel() {
                const _this = this;
                let data = {
                    type: 2 //0pc 1手机
                };
                indexCarousel(data).then(res => {
                    _this.jydtList = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            toNewPage(ind) {
                if (ind == 1) { //嘉园动态
                    this.$router.push('/dynamic');
                } else if (ind == 2) { //通知公告
                    this.$router.push('/announcements');
                } else if (ind == 3) { //学生活动
                    this.$router.push('/moral');
                } else if (ind == 4) { //教育教学
                    this.$router.push('/teach');
                }
            },
            makeList() {
                for (let i = 0; i < 7; i++) {
                    this.indexJYDTList.push({
                        id: i,
                        path: require('../assets/img/news-c01.png'),
                        createTime: '2022-12-06 09:44:24',
                        title: '欢迎省级领导莅临我校视察工作',
                    })
                }
            },
            // 教育教学轮播
            handleCurrentChange(num) {
                this.num = num;
            },


            // 嘉园动态
            indexJYDTGet() {
                const _this = this;
                let data = {
                    num: 7
                }
                indexJYDTGet(data).then(res => {
                    _this.indexJYDTList = res.data;
                    let arr = [],
                        newArr = [];
                    for (let item of _this.indexJYDTList) {
                        if (item.path&&item.path.includes(',')) {
                            arr = item.path.split(',');
                            newArr = arr.filter(item => item && item.trim());
                            item.path = newArr;
                        } else {
                            item.path = [item.path];
                        }
                    }
                    // if (_this.indexJYDTList[0].path.includes(',')) {
                    //     let arr = _this.indexJYDTList[0].path.split(',');
                    //     let newArr = arr.filter(item => item && item.trim());
                    //     console.log(newArr);
                    //     _this.indexJYDTList[0].path = newArr;
                    // } else {
                    //     _this.indexJYDTList[0].path = [_this.indexJYDTList[0].path];
                    // }
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },

            // 通知公告
            indexTZGGGet() {
                const _this = this;
                let data = {
                    num: 6
                };
                indexTZGGGet(data).then(res => {
                    _this.indexTZGGList = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 学生活动
            indexORTHERGet() {
                const _this = this;
                let data = {
                    num: 4
                }
                indexORTHERGet(data).then(res => {
                    _this.studentList = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            trimSpace(box, str) {
                var boxarr = [];
                var pos = box.indexOf(str);
                while (pos > -1) {
                    boxarr.push(pos);
                    pos = box.indexOf(str, pos + 1);
                }
                return boxarr;
            },
            // 教育教学
            indexStudyGet() {
                const _this = this;
                let data = {
                    num: 4
                }
                indexStudyGet(data).then(res => {
                    _this.educateList = res.data;
                    let str, conNum;
                    for (let item of _this.educateList) {
                        // if (item.content.includes('<img')) {
                        //     if (item.content.includes('&nbsp;')) {
                        //         let imgStart = this.trimSpace(item.content, '<img');
                        //         let imgEnd = this.trimSpace(item.content, '/>');
                        //         console.log(item.content);
                        //     } else {
                        //         item.content = '';
                        //     }
                        // }
                        if (item.content&&item.content!=null&&item.content.includes('&nbsp;')) { //有文字
                            str = item.content.substr(0, 4);
                            if (str == '<img') { //第一个是图片
                                conNum = item.content.lastIndexOf("\&nbsp;");
                                item.content = '&nbsp;' + item.content.substring(conNum, item.content.length);
                                if (item.content&&item.content.includes('<img')) {
                                    conNum = item.content.lastIndexOf("/>");
                                    console.log(conNum);
                                    item.content = item.content.substring(0, conNum);
                                }
                            }
                        } else { //没有文字
                            item.content = '';
                        }
                    }
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            // 进入详情
            articleDetail(item, type) {
                if (type == 1 && item.tid) {
                    this.$router.push({
                        path: `/search`,
                        query: {
                            id: item.id,
                            tid: item.type,
                            newTid: item.tid,
                            type: type
                        }
                    })
                    return
                }
                this.$router.push({
                    path: `/search`,
                    query: {
                        id: item.id,
                        type: type
                    }
                })
            },
            toNewDetail(id, type) {
                let routeData = this.$router.resolve({
                    name: "tzgg",
                    path: `/tzgg`,
                    query: {
                        id: id,
                        type: type
                    }
                });
                window.open(routeData.href, '_blank');
            }
        }
    }
</script>
<style scoped lang="scss">
    .foot-table img {
        position: absolute;
        height: 100%;
        top: 0;
    }
    
    .foot-table .tzgg-box {
        position: absolute;
        width: 82%;
        height: 350px;
        z-index: 99;
        color: rgba(5, 0, 0, 1);
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        overflow: scroll;
        height: 340px;
    }
    
    .foot-table .tzgg-box table {
        min-height: 100%;
    }
    
    .foot-table .recipe-block {
        top: 58% !important;
        height: 260px !important;
    }
    
    .foot-table .h3 {
        width: 80%;
        text-align: center;
        position: absolute;
        top: 17%;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 9;
        color: #000;
        font-size: 12px;
        h3 {
            margin-bottom: 5px;
        }
        ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: .15rem;
        }
    }
    
    .foot-table .tzgg-box::-webkit-scrollbar {
        display: none;
    }
    
    .foot-table .tzgg-box table {
        width: 100%;
        height: 70%;
        border: 1px solid #ddd;
        border-collapse: collapse;
    }
    
    .foot-table .tzgg-box table td,
    .foot-table .tzgg-box table th {
        font-size: 14px;
    }
    
    .foot-table .tzgg-box table td,
    .foot-table .tzgg-box table th {
        border: 1px solid #ddd;
    }
    
    .foot-table .tzgg-box table th,
    .foot-table .tzgg-box table td {
        padding-left: 3px;
        text-align: center;
    }
    
    .educate-con {
        width: 80%;
        max-width: 1200px;
        margin: 20px auto 0;
        display: flex;
    }
    
    .educate-con img {
        width: 100%;
        height: auto;
    }
    
    .block,
    .block-box {
        width: 50%;
        text-align: left;
        height: 540px;
        position: relative;
    }
    .carousel-box{
        background: #f8f8f8;
        height: 540px;
        display: flex;
        align-items: center;
    }
    .block-box {
        background: #0C57A2;
        color: #FFFFFF;
        li {
            display: flex;
            align-items: center;
            padding: 20px;
            box-sizing: border-box;
            height: 134px;
            position: relative;
            opacity: .6;
            &:after {
                content: '';
                width: calc(100% - 40px);
                height: 1px;
                background: #FFFFFF;
                opacity: 0.36;
                position: absolute;
                right: 20px;
                bottom: 0;
            }
            &:last-child::after {
                content: '';
                width: 0;
            }
            .right-desc {
                width: calc(100% - 104px);
                /* width: 100%; */
                .embellish i {
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    border: 2px solid #FFFFFF;
                    border-radius: 50%;
                    margin-right: 6px;
                }
                p {
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    margin: 10px auto;
                }
                span {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-height: 50px;
                }
            }
        }
        li.active {
            opacity: 1;
        }
    }
    
    .block-box :v-deep span {
        color: #fff;
    }
    
    .small-mark {
        width: 64px;
        height: 64px;
        background: #FFFFFF;
        border-radius: 8px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;
        box-shadow: 2px 2px 5px rgba(255, 255, 255, .3);
        p {
            font-size: 32px;
            font-family: Georgia-Bold, Georgia;
            font-weight: bold;
            color: #186EC5;
            text-shadow: 1px 1px 3px #186EC5;
        }
        span {
            font-size: 12px;
            font-family: Georgia-Bold, Georgia;
            font-weight: bold;
            color: #050000;
        }
    }
    /* 标题 */
    
    .big-title {
        position: relative;
        width: 80%;
        max-width: 1200px;
        margin: 60px auto 19px;
        &>img {
            display: block;
            margin: auto;
            width: 644px;
        }
        .more {
            width: 120px;
            height: 36px;
            border-radius: 18px;
            border: 1px solid #E2E2E2;
            line-height: 30px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #050000;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            cursor: pointer;
            img {
                width: 15px;
                margin-left: 5px;
            }
        }
    }
    /* 新闻动态 */
    
    .news-content {
        position: relative;
        margin: 19px auto 50px;
        .bck {
            position: absolute;
            width: 100%;
            top: 0;
            right: 0;
        }
    }
    
    .news-box {
        display: flex;
        justify-content: space-between;
        width: 80%;
        max-width: 1200px;
        margin: auto;
        .left {
            width: 48%;
            background: #f8f8f8;
            border-radius: 8px;
            padding-bottom: 20px;
        }
    }
    
    .cover-img {
        width: 570px;
        height: 358px;
        border-radius: 10px;
    }
    
    .right {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }
    
    .small-img {
        position: relative;
    }
    
    .small-img .cover-img {
        width: 94%;
        height: 218px;
        border-radius: 10px;
    }
    
    .right ul {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    /* 新闻动态公用模块 */
    
    .news-con-box {
        height: 54px;
        color: #050000;
        text-align: left;
        padding: 9px;
        box-sizing: border-box;
        position: relative;
        .time {
            font-size: 12px;
            font-family: Georgia-Bold, Georgia;
            font-weight: bold;
            color: #050000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: 13px;
                height: auto;
            }
        }
    }
    
    .small-img .news-con-box {
        position: absolute;
        bottom: 0;
        width: 94%;
        height: 54px;
        background: rgba(0, 0, 0, .6);
        color: #fff;
        text-align: left;
        padding: 9px;
        box-sizing: border-box;
        border-radius: 0 0 10px 10px;
        .time {
            font-size: 12px;
            font-family: Georgia-Bold, Georgia;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
    
    .left .content-title {
        padding-top: 15px;
        margin-bottom: 4px;
    }
    
    .left .news-con-box::before {
        content: '';
        display: block;
        width: 40px;
        height: 4px;
        background: #C5181F;
        position: absolute;
    }
    
    .left .time {
        color: rgba(197, 24, 31, .5);
    }
    
    .right li.news-con-box {
        width: 94%;
        height: 80px;
        background: #F8F8F8;
        border-radius: 8px;
        border: 1px solid #E2E2E2;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 10px;
        .content-title {
            position: relative;
            padding-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .time {
            width: 100%;
            color: rgba(197, 24, 31, .5);
        }
    }
    
    .right li.news-con-box .content-title::after {
        content: '';
        display: block;
        width: 20px;
        height: 4px;
        background: #C5181F;
        position: absolute;
        top: 0;
    }
    
    .content-title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
    }
    /* 通知公告 */
    
    .announcement-box {
        background: url(../assets/img/school-news-bck.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        color: #fff;
        box-sizing: border-box;
        padding: 1px 0 35px;
    }
    
    .announcement-box .big-title .more {
        color: #fff;
    }
    
    .announcement-main {
        width: 82%;
        max-width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1% auto 0;
        img {
            width: 90%;
        }
    }
    
    .announcement-left ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    .announcement-left li {
        width: 46%;
        height: 90px;
        background: rgba(255, 255, 255, .2);
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;
        text-align: left;
        margin-bottom: 45px;
        position: relative;
        box-shadow: 2px 2px 20px rgba(0, 0, 0, .6);
        p {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 这里是超出几行省略 */
            overflow: hidden;
            width: 93%;
        }
        &:after {
            content: '';
            display: block;
            background: url(../assets/img/to-more-white.png) no-repeat;
            position: absolute;
            width: 18px;
            height: 8px;
            background: auto;
            right: 20px;
            bottom: 27px;
        }
        .date-box {
            position: absolute;
            width: 50px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 8px;
            top: -25px;
            left: -25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, .6);
            p {
                font-size: 16px;
                font-family: Georgia-Bold, Georgia;
                font-weight: bold;
                color: #23952C;
                text-align: center;
                text-shadow: 1px 1px 3px #23952C;
                width: 100%;
            }
            span {
                font-size: 10px;
                font-family: Georgia-Bold, Georgia;
                font-weight: bold;
                color: #050000;
                text-align: center;
            }
        }
    }
    
    .announcement-left li:last-child,
    .announcement-left li:nth-last-child(2) {
        margin-bottom: 0;
    }
    /* 学生活动 */
    
    .student-content {
        width: 80%;
        max-width: 1200px;
        margin: 0 auto 50px;
        display: flex;
        flex-wrap: wrap;
        color: #FFFFFF;
        text-align: left;
    }
    
    .student-content .cover-img {
        width: 100%;
        max-height: 222px;
        border-radius: 0;
    }
    
    .student-content .student-main {
        width: 25%;
    }
    
    .student-content .student-main>ul {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .student-content .student-main:first-child {
        background: #23952C;
    }
    
    .student-content .student-main:nth-child(2) {
        background: #C5181F;
    }
    
    .student-content .student-main:nth-child(3) {
        background: #FFB800;
    }
    
    .student-content .student-main:nth-child(4) {
        background: #186EC5;
    }
    
    .font-con {
        padding: 20px;
        box-sizing: border-box;
        height: 222px;
        position: relative;
        ul li {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
        .title {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            /*禁止换行显示*/
            white-space: nowrap;
            padding-top: 10px;
            margin-bottom: 10px;
            position: relative;
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 32px;
                height: 4px;
                background: #FFFFFF;
                top: 0px;
            }
        }
        .desc {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
            height: 100px;
            .subtitle {
                font-size: 14px;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                /*禁止换行显示*/
                white-space: nowrap;
                margin-bottom: 5px;
            }
            .summary {
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        ul {
            display: flex;
            justify-content: space-between;
        }
    }
    
    .block img {
        transition: all 0.6s;
    }
    
    .block img:hover {
        transform: scale(1);
    }
    
    .mobile-style {
        display: none;
    }
    
    .tzgg-content {
        width: 85%;
        margin: auto;
    }
    /* 通知公告 */
    
    .announcement-left {
        width: calc(100% - 580px);
    }
    
    .announcement-right {
        width: 580px;
    }
    
    @media screen and (max-width: 1440px) {
        /* 嘉园动态 */
        .cover-img {
            width: auto;
        }
        .small-img .cover-img,
        .small-img .news-con-box {
            width: 94%;
        }
    }
    
    @media screen and (max-width: 1100px) {
        .announcement-main {
            display: block;
        }
        /* 通知公告 */
        .announcement-left {
            width: 100%;
        }
        .announcement-right {
            margin: auto;
        }
    }
    
    @media screen and (max-width: 1024px) {
        .pc-style {
            display: none;
        }
        .mobile-style {
            display: block;
        }
    }
</style>
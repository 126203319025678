import axios from 'axios'

// 环境的切换
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = ''
}
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'
axios.defaults.crossDomain = true
    // axios.interceptors.request.use(
    //     config => {
    //         console.log(sessionStorage.getItem('token'));
    //         if (sessionStorage.getItem('token')) {
    //             //token字段是要和后端协商好的
    //             config.headers.common["token"] = localStorage.getItem('token');
    //             // debugger
    //         }
    //         return config;
    //     },
    //     error => {
    //         return Promise.reject(error);
    //     });
    // http request拦截器 添加一个请求拦截器
    // axios.interceptors.request.use(function(config) {
    //     // Do something before request is sent
    //     //window.localStorage.getItem("accessToken") 获取token的value
    //     let token = sessionStorage.getItem("token")
    //     if (token) {
    //         //将token放到请求头发送给服务器,将tokenkey放在请求头中
    //         config.headers.token = token;
    //         //也可以这种写法
    //         // config.headers['token'] = token;
    //         return config;
    //     }
    // }, function(error) {
    //     // Do something with request error
    //     return Promise.reject(error);
    // });


// if (sessionStorage.getItem('token')) {
//     axios.defaults.headers.common['token'] = sessionStorage.getItem('token');

// }
// // 请求拦截器
axios.interceptors.request.use(
        config => {
            // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
            // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
            const token = localStorage.getItem('token')
                // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImVjYTExZDJkLTFhM2YtNGVhYS05YWY0LTk2ZDhkYWE0YzI0OCJ9.vDsFlN1QZfhBi73FaaHHN4s1iyleDEP-RADwg9M6LtNwwjKBC32Kr4P0euhK5FssD_-K9cRiaSXZJN3uaK6spg'
            if (config.isUpload) {
                config.data = JSON.stringify(config.data)
                config.headers = {
                    'Content-Type': 'application/json',
                    // token
                }
                token && (config.headers.authorization = token)
            } else {
                config.headers = {
                    'Content-Type': 'multipart/form-data',
                    // token
                }
                token && (config.headers.authorization = token)
            }
            return config
        },
        error => {
            return Promise.error(error)
        })
    // // 响应拦截器
    // axios.interceptors.response.use(
    //   response => {
    //     if (response.status === 200) {
    //       return Promise.resolve(response)
    //     } else {
    //       return Promise.reject(response)
    //     }
    //   },
    //   // 服务器状态码不是200的情况
    //   error => {
    //     if (error.response.status) {
    //       switch (error.response.status) {
    //         // 401: 未登录
    //         // 未登录则跳转登录页面，并携带当前页面的路径
    //         // 在登录成功后返回当前页面，这一步需要在登录页操作。
    //         case 401:
    //           router.replace({
    //             path: '/login',
    //             query: { redirect: router.currentRoute.fullPath }
    //           })
    //           break
    //         // 403 token过期
    //         // 登录过期对用户进行提示
    //         // 清除本地token和清空vuex中token对象
    //         // 跳转登录页面
    //         case 403:
    //           Toast({
    //             message: '登录过期，请重新登录',
    //             duration: 1000,
    //             forbidClick: true
    //           })
    //           // 清除token
    //           localStorage.removeItem('token')
    //           store.commit('loginSuccess', null)
    //           // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
    //           setTimeout(() => {
    //             router.replace({
    //               path: '/login',
    //               query: {
    //                 redirect: router.currentRoute.fullPath
    //               }
    //             })
    //           }, 1000)
    //           break
    //         // 404请求不存在
    //         case 404:
    //           Toast({
    //             message: '网络请求不存在',
    //             duration: 1500,
    //             forbidClick: true
    //           })
    //           break
    //         // 其他错误，直接抛出错误提示
    //         default:
    //           Toast({
    //             message: error.response.data.message,
    //             duration: 1500,
    //             forbidClick: true
    //           })
    //       }
    //       return Promise.reject(error.response)
    //     }
    //   }
    // )

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    // console.log('加载中');
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            // console.log('加载结束');
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}
export function getUrl(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url + params).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function delPoster(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
                params: params
            })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}
// restful 风格的get请求
export function restfulGet(url, urlParams, params) {
    return new Promise((resolve, reject) => {
        if (params === undefined || params == null) {
            axios.get(url + urlParams).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.data)
            })
        } else {
            axios.get(url + urlParams, {
                params: params
            }).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.data)
            })
        }

    })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

// json格式
export function postJson(url, data = {}, config = { showLoading: true, isUpload: false }) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, config).then(response => {
            resolve(response.data)
        }, err => {
            reject(err)
        })
    })
}
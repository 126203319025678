import {get, post } from './http'
import { baseUrl } from '../../public/js/http'





/************** 首页 */
//搜索
export const dimSearch = p => get(baseUrl + '/app/dimSearch', p)

//搜索详情
export const dimSearchDetial = p => get(baseUrl + '/app/dimSearchDetial', p)

//顶部信息栏
export const baseList = p => get(baseUrl + '/app/listBase', p)

//招贤纳士
export const findPeople = p => get(baseUrl + '/app/findPeople', p)


//轮播
export const indexCarousel = p => get(baseUrl + '/app/indexCarousel', p)

//嘉园动态信息查询
export const indexJYDTGet = p => get(baseUrl + '/app/indexPush', p)

//通知公告
export const indexTZGGGet = p => get(baseUrl + '/app/indexTZGGGet', p)

//学生活动
export const indexORTHERGet = p => get(baseUrl + '/app/indexORTHERGet', p)

//教育教学
export const indexStudyGet = p => get(baseUrl + '/app/indexStudyGet', p)

//校园动态
export const indexPushDetail = p => get(baseUrl + '/app/indexPushDetail', p)

//工作安排
export const workArrange = p => get(baseUrl + '/app/workArrange', p)

//食谱
export const weeklyRecipe = p => get(baseUrl + '/app/weeklyRecipe', p)


/************** 浏览量 */
//浏览量的增加
export const increamPageView = p => get(baseUrl + '/app/increamPageView', p)

//获取当前页面浏览量 改造后的
export const getPageViewByNameAndId = p => get(baseUrl + '/app/getPageViewByNameAndId', p)



/**************认识嘉小 */
//菜单主图
export const menuPic = p => get(baseUrl + '/app/menuPic', p)

//菜单主图手机端
export const phoneImg = p => get(baseUrl + '/app/phoneImg', p)

//菜单树
export const indexTree = p => get(baseUrl + '/app/indexTree', p)

//学校简介
export const schoolProfile = p => get(baseUrl + '/app/schoolProfile', p)

//领导班子
export const leaderGroupList = p => get(baseUrl + '/app/leaderGroupList', p)

//领导班子详情
export const leaderGroupDetail = p => get(baseUrl + '/app/leaderGroupDetail', p)

// 学校荣誉
export const schoolHonor = p => get(baseUrl + '/app/schoolHonor', p)

// 美丽校园列表
export const beautifulSchool = p => get(baseUrl + '/app/beautifulSchool', p)

// 美丽校园详情
export const beautifulSchoolDetail = p => get(baseUrl + '/app/beautifulSchoolDetail', p)

// 部门集合
export const deptSelect = p => get(baseUrl + '/app/deptSelect', p)

// 部门机构与负责人
export const deptAndName = p => get(baseUrl + '/app/deptAndName', p)

// 党支部委员会
export const deptCommittee = p => get(baseUrl + '/app/deptCommittee', p)





/**************党支部 */
// 党支部集合
export const partyBranch = p => get(baseUrl + '/app/partyBranch', p)

// 党支部详情
export const partyBranchDetail = p => get(baseUrl + '/app/partyBranchDetail', p)


/**************嘉园动态 */
// 嘉园动态集合
export const jiaYuanDynamic = p => get(baseUrl + '/app/jiaYuanDynamic', p)

// 嘉园动态集合
export const jiaYuanDynamicDetail = p => get(baseUrl + '/app/jiaYuanDynamicDetail', p)


/**************通知公告 */
// 通知公告列表
export const notice = p => get(baseUrl + '/app/notice', p)

// 通知公告详情
export const noticeDetail = p => get(baseUrl + '/app/noticeDetail', p)


/**************德育阵地 */
// 德育阵地集合
export const educationPlace = p => get(baseUrl + '/app/educationPlace', p)

// 德育阵地详情
export const educationPlaceDetail = p => get(baseUrl + '/app/educationPlaceDetail', p)



/**************教学科研 */
// 教学科研列表
export const teachResearch = p => get(baseUrl + '/app/teachResearch', p)

// 教学科研详情
export const teachResearchDetail = p => get(baseUrl + '/app/teachResearchDetail', p)



/**************信息公开 */
// 办事指南列表
export const workGuide = p => get(baseUrl + '/app/workGuide', p)

// 办事指南详情 
export const workGuideDetail = p => get(baseUrl + '/app/workGuideDetail', p)

// 家长留言列表
export const parentMessage = p => get(baseUrl + '/app/parentMessage', p)

// 家长留言详情 
export const parentMessageDetail = p => get(baseUrl + '/app/parentMessageDetail', p)

// 添加留言 
export const insertMessage = p => post(baseUrl + '/app/insertMessage', p)

// 学期校历列表
export const semesterHistory = p => get(baseUrl + '/app/semesterHistory', p)

// 学期校历详情 
export const semesterHistoryDetail = p => get(baseUrl + '/app/semesterHistoryDetail', p)

// 教育法规列表
export const educationStatute = p => get(baseUrl + '/app/educationStatute', p)

// 教育法规详情 
export const educationStatuteDetail = p => get(baseUrl + '/app/educationStatuteDetail', p)





// //前台转盘页面
// export const activityPrizeList = p => get(baseUrl + '/mini-app/activityPrizeList', p)

// //活动规则
// export const activityRule = p => get(baseUrl + '/mini-app/activityRule', p)

// //点击抽奖
// export const getDrawType = p => post(baseUrl + '/mini-app/getDrawType', p)

// //中奖记录
// export const awardRecord = p => get(baseUrl + '/mini-app/awardRecord', p)

// //中奖详情
// export const activityDetails = p => get(baseUrl + '/mini-app/activityDetails', p)

// //中奖名单
// export const historyGiftList = p => getUrl(baseUrl + '/mini-app/historyGiftList/', p)
//     // localStorage.getItem('activityId')



































// // 添加转发记录
// export const addShare = (urlp, p) => restfulGet(baseUrl + `/app/poster/addShare`, urlp, p)


// // 登录通用
// // export const login = p => post(baseUrl + `/app/login/${appCode}/${sessionStorage.getItem('companyId')}`, p) //登录
// // export const autoLogin = p => post(baseUrl + `/app/autoLogin/${appCode}/${sessionStorage.getItem('companyId')}`, p) //自动登录
// // export const code2Session = p => post(baseUrl + `/app/code2Session/${appCode}/${sessionStorage.getItem('companyId')}`, p) //企业微信客户端获取SESSION_KEY
// export const code2Session = p => post(baseUrl + `/app/code2Session/${appCode}/${sessionStorage.getItem('companyId')}`, p) //企业微信客户端获取SESSION_KEY
//     //创建调用JSAPI时所需要的签名
// export const getJsConfig = p => post(baseUrl + `/app/getJsConfig/${appCode}/${sessionStorage.getItem('companyId')}`, p)
// export const getAgentIdJsConfig = p => post(baseUrl + `/app/getAgentIdJsConfig/${appCode}/${sessionStorage.getItem('companyId')}`, p) //企业微信客户端获取SESSION_KEY
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

// 获取原replace方法 进行重写
const replaceOrigin = VueRouter.prototype.replace;
const pushOrigin = VueRouter.prototype.push;
// 重写replace方法
/*
  location 参数 push replace ({path:"/"})
  切记不要使用箭头函数,携程箭头会导致上下文this一致
*/
VueRouter.prototype.replace = function(location) {
    // 目的是抓异常
    return replaceOrigin.call(this, location).catch((error) => error);
};
VueRouter.prototype.push = function(location) {
    // 目的是抓异常
    return pushOrigin.call(this, location).catch((error) => error);
};

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Index',
    component: Index
}, {
    path: '/know',
    name: 'Know',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import ( /* webpackChunkName: "about" */ '../views/Know.vue')
}, {
    path: '/party',
    name: 'Party',
    component: () =>
        import ('../views/Party.vue')
}, {
    path: '/dynamic',
    name: 'Dynamic',
    component: () =>
        import ('../views/Dynamic.vue')
}, {
    path: '/announcements',
    name: 'Announcements',
    component: () =>
        import ('../views/Announcements.vue')
}, {
    path: '/moral',
    name: 'Moral',
    component: () =>
        import ('../views/Moral.vue')
}, {
    path: '/teach',
    name: 'Teach',
    component: () =>
        import ('../views/Teach.vue')
}, {
    path: '/information',
    name: 'Information',
    component: () =>
        import ('../views/Information.vue')
}, {
    path: '/search',
    name: 'Search',
    component: () =>
        import ('../views/Search.vue')
}, {
    path: '/tzgg',
    name: 'Tzgg',
    component: () =>
        import ('../components/Tzgg.vue')
}, {
    path: '/career',
    name: 'Career',
    component: () =>
        import ('../views/Careers.vue')
}, {
    path: '/404',
    name: '404',
    component: () =>
        import ('../views/404.vue')
}, ]

const router = new VueRouter({
    // mode: '',
    base: process.env.BASE_URL,
    routes
})

export default router
<template>
    <div class="page">
        <!-- 嘉园动态 -->
        <div class="big-title">
            <img src="../assets/img/news-title.png" alt="">
        </div>
        <div class="news-content">
            <div class="block">
                <el-carousel height="6rem" @change="c">
                    <el-carousel-item v-for="(item,index) of indexJYDTList" :key="item.id" v-if="index==0">
                        <div class="cover-img" v-for="(ite) of item.path" :key="ite" @click="articleDetail(item,1)">
                            <img v-lazy="ite" alt="">
                        </div>
                        <!-- <div class="cover-img" v-if="item.path.includes(',')" v-for="(ite,ind) of item.path.split(',')" :key="ite.id" @click="articleDetail(item,1)">
                            <img :src="ite" alt="">
                        </div>
                        <div class="cover-img" v-else @click="articleDetail(item,1)">
                            <img :src="item.path" alt="">
                        </div> -->
                    </el-carousel-item>
                    <!-- <el-carousel-item v-for="(item,index) of jydtList" :key="item.id">
                        <div class="cover-img" @click="articleDetail(item,1)">
                            <img :src="item.picPath" alt="">
                        </div>
                    </el-carousel-item> -->
                </el-carousel>
                <div class="news-con-box" v-for="(item,index) of indexJYDTList" :key="item.id" v-if="index==0">
                    <div class="content-title">{{item.title}}</div>
                    <div class="time" v-if="item.createTime">{{item.createTime.substring(0,10)}} <img src="../assets/img/to-more.png" alt=""></div>
                </div>
            </div>
            <div class="news-box">
                <div class="news-con-box" v-if="index>2&&index<7" @click="articleDetail(item,1)" v-for="(item,index) of indexJYDTList" :key="item.id">
                    <div class="content-title">{{item.title}}</div>
                    <div class="time" v-if="item.createTime">{{item.createTime.substring(0,10)}} <img src="../assets/img/to-more.png" alt=""></div>
                </div>
            </div>
        </div>
        <div class="more" @click="toNewPage(1)">查看更多<img src="../assets/img/to-more.png" alt=""></div>
        <!-- 通知公告 -->
        <div class="announcement-box">
            <div class="big-title">
                <img src="../assets/img/school-news.png" alt="">
            </div>
            <div class="announcement-main">
                <div class="announcement-left">
                    <ul>
                        <li @click="articleDetail(item,2)" v-for="(item) of indexTZGGList" :key="item.id">
                            <div class="date-box" v-if="item.createTime">
                                <p>{{item.createTime.substring(5,11)}}</p>
                                <span>{{item.createTime.substring(0,4)}}</span>
                            </div>
                            <p>{{item.title}}</p>
                        </li>
                    </ul>
                </div>
                <div class="announcement-right block">
                    <el-carousel height="7.6rem" :autoplay="flag" @change="c" @mouseleave.native="delHandleMouseleave" @mouseenter.native="delHandleMouseEnter">
                        <el-carousel-item class="foot-table" v-for="(item,index) of footTable" :key="index">
                            <div class="tzgg-box" style="margin-top: 3%;" v-if="index==0">
                                <table class="fir-table" style="border-collapse:collapse;">
                                    <thead>
                                        <th>星期</th>
                                        <th>时间</th>
                                        <th>内容</th>
                                        <th>地点</th>
                                        <th>负责人/部门</th>
                                    </thead>
                                    <tbody v-if="footTable[index]&&footTable[index].workArrangesVos">
                                        <tr style="padding: 5px 0;" v-for="(ite,ind) of footTable[index].workArrangesVos" :key="ind">
                                            <td>
                                                <p :title="ite.work">{{ite.work}}</p>
                                            </td>
                                            <td style="width:18%">
                                                <p v-for="(ele,i) of ite.time" :key="i">
                                                    <span v-if="ele.includes('：')">
                                                        {{(ele.split('：')[0].length==1?'0'+ele.split('：')[0]:ele.split('：')[0])+'：'+(ele.split('：')[1].length==1?'0'+ele.split('：')[1]:ele.split('：')[1])}}
                                                    </span>
                                                    <span v-if="ele.includes(':')">
                                                        {{(ele.split(':')[0].length==1?'0'+ele.split(':')[0]:ele.split(':')[0])+':'+(ele.split(':')[1].length==1?'0'+ele.split(':')[1]:ele.split(':')[1])}}
                                                    </span>
                                                </p>
                                            </td>
                                            <td>
                                                <p :title="ele" v-for="(ele,i) of ite.details" :key="i">
                                                    {{ele.length>4?ele.substring(0,4)+'...':ele}}
                                                </p>
                                            </td>
                                            <td>
                                                <p v-for="(ele,i) of ite.place" :key="i">
                                                    {{ele.length>3?ele.substring(0,3)+'...':ele}}
                                                </p>
                                            </td>
                                            <td>
                                                <p v-for="(ele,i) of ite.deptName" :key="i">
                                                    {{ele}}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- 食谱 -->
                            <div v-else>
                                <div class="h3">
                                    <!-- <h3>{{footTable[index].title}}</h3> -->
                                    <ul>
                                        <li>本周主推：<span>{{footTable[index].pushWeek?footTable[index].pushWeek:'-'}}</span></li>
                                        <li>陪餐领导：<span>{{footTable[index].leaderWeek?footTable[index].leaderWeek:'-'}}</span></li>
                                        <li>日期：<span>{{footTable[index].date?footTable[index].date:'-'}}</span></li>
                                    </ul>
                                </div>
                                <div class="tzgg-box recipe-block">
                                    <table style="border-collapse:collapse;" class="sec-table">
                                        <thead>
                                            <th>星期</th>
                                            <th>早餐食谱</th>
                                            <th>午餐食谱</th>
                                            <th>午点</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(ite,ind) of footTable[index].weeklyRecipeVos" :key="ite.id">
                                                <td>{{ite.week}}</td>
                                                <td style="width:30%">
                                                    <p v-for="(ele,i) of ite.name" :key="ele">{{ele}}</p>
                                                </td>
                                                <td>
                                                    <p v-for="(ele,i) of ite.food" :key="ele">{{ele}}</p>
                                                </td>
                                                <td>
                                                    <p v-for="(ele,i) of ite.soup" :key="ele">{{ele}}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <img v-if="index==0" style="width:100%" src="../assets/img/work.png" alt="">
                            <img v-else style="width:100%" src="../assets/img/foodList.png" alt="">
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="more" @click="toNewPage(2)">查看更多<img src="../assets/img/to-more-white.png" alt=""></div>
        </div>
        <!-- 学生活动 -->
        <div class="big-title">
            <img src="../assets/img/student-activite.png" alt="">
        </div>
        <div class="student-content">
            <div class="student-main" @click="articleDetail(item,3)" v-for="(item,index) of studentList" :key="item.id">
                <ul v-if="index%2==0">
                    <li class="cover-img" v-if="item.path.includes(',')"><img v-lazy="item.path.split(',')[0]" alt=""></li>
                    <li class="cover-img" v-else><img v-lazy="item.path" alt=""></li>
                    <li class="font-con">
                        <div class="title">{{item.title}}</div>
                        <div class="desc">
                            <div class="subtitle" v-if="item.subtitle">{{item.subtitle}}</div>
                            <div class="summary" v-if="item.summary">{{item.summary}}</div>
                        </div>
                        <ul>
                            <li># {{item.author&&item.author!='undefined'?item.author:'来源'}}</li>
                            <li v-if="item.createTime">{{item.createTime.substring(0,10)}}</li>
                        </ul>
                    </li>
                </ul>
                <ul v-else>
                    <li class="font-con">
                        <div class="title">{{item.title}}</div>
                        <div class="desc">
                            <div class="subtitle" v-if="item.subtitle">{{item.subtitle}}</div>
                            <div class="summary" v-if="item.summary">{{item.summary}}</div>
                        </div>
                        <ul>
                            <li># {{item.author&&item.author!='undefined'?item.author:'来源'}}</li>
                            <li v-if="item.createTime">{{item.createTime.substring(0,10)}}</li>
                        </ul>
                    </li>
                    <li class="cover-img" v-if="item.path.includes(',')"><img v-lazy="item.path.split(',')[0]" alt=""></li>
                    <li class="cover-img" v-else><img v-lazy="item.path" alt=""></li>
                </ul>
            </div>
        </div>
        <div class="more" @click="toNewPage(3)">查看更多<img src="../assets/img/to-more.png" alt=""></div>
        <!-- 教育教学 -->
        <div class="big-title">
            <img src="../assets/img/school.png" alt="">
        </div>
        <div class="educate-con">
            <el-carousel height="10rem" @change="c">
                <el-carousel-item v-for="(item) of educateList" :key="item.id">
                    <div v-if="item.path" @click="articleDetail(item,4)">
                        <img v-if="item.path&&item.path.includes(',')" v-lazy="item.path.split(',')[0]" alt="">
                        <img v-else v-lazy="item.path" alt="">
                    </div>
                    <!-- <img @click="articleDetail(item,4)" :src="item.path" alt=""> -->
                    <div @click="articleDetail(item,4)" class="block-box">
                        <div class="small-mark" v-if="item.createTime">
                            <p>{{item.createTime.substring(8,10)}}</p>
                            <span>{{item.createTime.substring(0,7)}}</span>
                        </div>
                        <div class="right-desc">
                            <p class="embellish"><i></i><i></i><i></i></p>
                            <p>{{item.title}}</p>
                            <span v-html="item.content"></span>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="more" @click="toNewPage(4)">查看更多<img src="../assets/img/to-more.png" alt=""></div>
    </div>
</template>
<script>
    import {
        workArrange,
        weeklyRecipe
    } from "@/request/api";
    export default {
        props: ['indexJYDTList', 'jydtList', 'studentList', 'indexTZGGList', 'educateList'],
        data() {
            return {
                num: 0,
                flag: true,
                footTable: [],
            }
        },
        mounted() {
            this.workArrange();
            this.weeklyRecipe();
            console.log(this.footTable);
        },
        methods: {
            workArrange() {
                const _this = this;
                workArrange().then(res => {
                    _this.footTable[0] = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            weeklyRecipe() {
                const _this = this;
                weeklyRecipe().then(res => {
                    _this.footTable[1] = res.data;
                    _this.footTable = JSON.parse(JSON.stringify(_this.footTable));
                    console.log(_this.footTable);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            delHandleMouseleave() {
                this.$nextTick(() => {
                    this.flag = true;
                })
            },
            delHandleMouseEnter() {
                this.$nextTick(() => {
                    this.flag = false;
                })
            },
            c(num) {
                this.num = num;
            },
            toNewPage(ind) {
                if (ind == 1) { //嘉园动态
                    this.$router.push('/dynamic');
                } else if (ind == 2) { //通知公告
                    this.$router.push('/announcements');
                } else if (ind == 3) { //学生活动
                    this.$router.push('/moral');
                } else if (ind == 4) { //教育教学
                    this.$router.push('/teach');
                }
            },
            // 进入详情
            articleDetail(item, type) {
                console.log(item);
                if (type == 1 && item.tid) {
                    this.$router.push({
                        path: `/search`,
                        query: {
                            id: item.id,
                            tid: item.type,
                            type: type
                        }
                    })
                    return
                }
                this.$router.push({
                    path: `/search`,
                    query: {
                        id: item.id,
                        type: type
                    }
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    .foot-table img {
        position: absolute;
        height: 100%;
        top: 0;
    }
    
    .foot-table .tzgg-box {
        position: absolute;
        z-index: 99;
        color: rgba(5, 0, 0, 1);
        top: 20%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
        max-height: 4.2rem;
        overflow: hidden;
        overflow: scroll;
    }
    
    .foot-table .tzgg-box::-webkit-scrollbar {
        display: none;
    }
    
    .foot-table .h3 {
        width: 80%;
        text-align: center;
        position: absolute;
        top: 1.2rem;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 9;
        color: #000;
        font-size: 12px;
        ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
    
    .foot-table .recipe-block {
        top: 28%;
        /* height: 5rem; */
    }
    
    .foot-table .tzgg-box table {
        width: 100%;
        font-size: .24rem;
        border: 1px solid #ddd;
        /* border-collapse: collapse; */
    }
    
    .foot-table .tzgg-box table th,
    .foot-table .tzgg-box table td {
        border: 1px solid #ddd;
    }
    
    .foot-table .tzgg-box table th,
    .foot-table .tzgg-box table td {
        text-align: center;
    }
    
    .foot-table .tzgg-box table tr {
        margin-bottom: 10px;
    }
    /* 工作台 */
    
    .foot-table .tzgg-box table.fir-table th:first-child,
    .foot-table .tzgg-box table.fir-table td:first-child {
        width: 18%;
    }
    
    .foot-table .tzgg-box table.fir-table th:nth-child(2),
    .foot-table .tzgg-box table.fir-table td:nth-child(2) {
        width: 14%;
    }
    
    .foot-table .tzgg-box table.fir-table th:nth-child(3),
    .foot-table .tzgg-box table.fir-table td:nth-child(3) {
        width: 24%;
    }
    
    .foot-table .tzgg-box table.fir-table th:nth-child(4),
    .foot-table .tzgg-box table.fir-table td:nth-child(4) {
        width: 16%;
    }
    
    .foot-table .tzgg-box table.fir-table th:nth-child(5),
    .foot-table .tzgg-box table.fir-table td:nth-child(5) {
        width: 28%;
    }
    /* 标题 */
    
    .big-title {
        position: relative;
        width: 80%;
        max-width: 1200px;
        margin: .8rem auto .4rem;
        &>img {
            display: block;
            margin: auto;
            width: 100%;
        }
    }
    
    .more {
        width: 120px;
        height: 36px;
        border-radius: 18px;
        border: 1px solid #E2E2E2;
        line-height: 30px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #050000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: .27rem auto .4rem;
        img {
            width: 15px;
            margin-left: 5px;
        }
    }
    /* 嘉园动态 */
    
    .block {
        width: 9.47rem;
        margin: auto;
    }
    
    .news-content .cover-img {
        width: 9.47rem;
        height: 5.95rem;
    }
    
    .news-con-box {
        width: 9.47rem;
        background: #F8F8F8;
        border-radius: 0.13rem;
        color: #050000;
        text-align: left;
        padding: .27rem;
        box-sizing: border-box;
        position: relative;
        margin-bottom: .27rem;
        .time {
            font-size: 0.32rem;
            font-family: Georgia-Bold, Georgia;
            font-weight: bold;
            color: #C5181F;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: .28rem;
                height: auto;
            }
        }
        .content-title {
            padding-top: .15rem;
            margin-bottom: .27rem;
            font-size: 0.43rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
        }
    }
    
    .news-content .news-con-box::before {
        content: '';
        display: block;
        width: 0.66rem;
        height: 0.07rem;
        background: #C5181F;
        position: absolute;
    }
    
    .news-box {
        width: 9.47rem;
        margin: .4rem auto 0;
        .left {
            width: 48%;
            background: #f8f8f8;
            border-radius: 8px;
            padding-bottom: 20px;
        }
    }
    
    .educate-con {
        width: 9.47rem;
        margin: 20px auto 0;
        img {
            width: 9.47rem;
            height: 7.1rem;
            display: block;
        }
    }
    
    .block-box {
        background: #186EC5;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        padding: .27rem;
        box-sizing: border-box;
        height: 2.4rem;
        position: relative;
        &:after {
            content: '';
            width: calc(100% - 1.6rem);
            height: 1px;
            background: #FFFFFF;
            opacity: 0.36;
            position: absolute;
            right: 20px;
            bottom: 0;
        }
        &:last-child::after {
            content: '';
            width: 0;
        }
        .right-desc {
            width: calc(100% - 1.5rem);
            .embellish {
                display: flex;
            }
            .embellish i {
                width: 0.19rem;
                height: 0.19rem;
                border: 0.04rem solid #FFFFFF;
                border-radius: 50%;
                margin-right: .11rem;
            }
            p {
                font-size: 0.43rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                margin-bottom: .13rem;
                overflow: hidden;
                /*超出部分隐藏*/
                text-overflow: ellipsis;
                /* 超出部分显示省略号 */
                white-space: nowrap;
                /*规定段落中的文本不进行换行 */
            }
            span {
                font-size: 0.32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                max-height: 50px;
            }
        }
    }
    
    .small-mark {
        width: 1.33rem;
        height: 1.33rem;
        background: #FFFFFF;
        border-radius: 0.21rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: .27rem;
        p {
            font-size: 0.64rem;
            font-family: Georgia-Bold, Georgia;
            font-weight: bold;
            color: #23952C;
        }
        span {
            font-size: 0.27rem;
            font-family: Georgia-Bold, Georgia;
            font-weight: bold;
            color: #050000;
        }
    }
    /* 新闻动态 */
    
    .right {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }
    
    .small-img {
        position: relative;
    }
    
    .small-img .cover-img {
        width: 285px;
        height: 218px;
        border-radius: 10px;
    }
    /* 通知公告 */
    
    .announcement-box {
        background: url(../assets/img/school-news-bck.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        color: #fff;
        padding: .4rem 0;
        box-sizing: border-box;
        .more {
            color: #fff;
        }
    }
    
    .announcement-box .big-title .more {
        color: #fff;
    }
    
    .announcement-main {
        display: flex;
        flex-direction: column;
        img {
            width: 90%;
            display: block;
            margin: auto;
        }
    }
    
    .announcement-left {
        width: 100%;
        box-sizing: border-box;
        padding-right: .27rem;
        padding-left: .94rem;
    }
    
    .announcement-left ul {
        display: block;
        margin: auto;
    }
    
    .announcement-left li {
        width: 100%;
        height: 90px;
        background: rgba(255, 255, 255, .2);
        border-radius: 8px;
        padding: .27rem .27rem .27rem .77rem;
        box-sizing: border-box;
        text-align: left;
        margin-bottom: .94rem;
        position: relative;
        box-shadow: 2px 2px 20px rgba(0, 0, 0, .6);
        p {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
        }
        &:after {
            content: '';
            display: block;
            background: url(../assets/img/to-more-white.png) no-repeat;
            position: absolute;
            width: 18px;
            height: 8px;
            background: auto;
            right: 20px;
            bottom: 27px;
        }
        .date-box {
            position: absolute;
            width: 1.33rem;
            height: 1.33rem;
            background: #FFFFFF;
            border-radius: 0.21rem;
            top: -.67rem;
            left: -.67rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, .6);
            p {
                font-size: 0.44rem;
                font-family: Georgia-Bold, Georgia;
                font-weight: bold;
                color: #23952C;
                text-align: center;
            }
            span {
                font-size: 0.32rem;
                font-family: Georgia-Bold, Georgia;
                font-weight: bold;
                color: #050000;
                text-align: center;
            }
        }
    }
    
    .announcement-left li:last-child {
        margin-bottom: 0;
    }
    /* 学生活动 */
    
    .student-content {
        width: 9.47rem;
        margin: 0 auto .4rem;
        display: flex;
        flex-wrap: wrap;
        color: #FFFFFF;
        text-align: left;
    }
    
    .student-content .cover-img {
        width: 4.73rem;
        height: 3.56rem;
    }
    
    .student-content .student-main {
        width: 100%;
    }
    
    .student-content .student-main>ul {
        display: flex;
        height: 100%;
        justify-content: space-between;
    }
    
    .student-content .student-main:first-child {
        background: #23952C;
    }
    
    .student-content .student-main:nth-child(2) {
        background: #C5181F;
    }
    
    .student-content .student-main:nth-child(3) {
        background: #FFB800;
    }
    
    .student-content .student-main:nth-child(4) {
        background: #186EC5;
    }
    
    .font-con {
        width: 50%;
        padding: .27rem;
        box-sizing: border-box;
        position: relative;
        ul li {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
        .title {
            font-size: 0.43rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            overflow: hidden;
            text-overflow: ellipsis;
            /*禁止换行显示*/
            white-space: nowrap;
            padding-top: .21rem;
            margin-bottom: .21rem;
            position: relative;
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 32px;
                height: 4px;
                background: #FFFFFF;
                top: 0px;
            }
        }
        .desc {
            display: block;
            font-size: 0.32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            max-height: 2rem;
            .subtitle {
                font-size: .26rem;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                /*禁止换行显示*/
                white-space: nowrap;
                margin-bottom: .04rem;
            }
            .summary {
                font-size: .24rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: .27rem;
            box-sizing: border-box;
        }
    }
    
    .block img {
        transition: all 0.6s;
    }
    
    .mobile-style {
        display: none;
    }
    
    @media screen and (max-width: 800px) {
        /* 共用标题 */
        .more {
            width: 2.13rem;
            height: 0.64rem;
            border-radius: 0.32rem;
            border: 0.04rem solid #E2E2E2;
            font-size: 0.32rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #050000;
            display: flex;
            align-items: center;
            img {
                width: .28rem;
                margin-left: .15rem;
            }
        }
    }
</style>
<template>
    <div class="page">
        <el-carousel class="pc-style" :height="bannerHeight+'px'">
            <el-carousel-item v-for="item in swiperList" :key="item.id">
                <img :src="item.picPath" alt="">
            </el-carousel-item>
        </el-carousel>
        <van-swipe class="mobile-style" :autoplay="3000" lazy-render>
            <van-swipe-item v-for="item in swiperList" :key="item.picPath">
              <img v-lazy="item.picPath" style="width:100%" />
            </van-swipe-item>
        </van-swipe>
    </div>
    
  </template>
<script>
    import {
        indexCarousel
    } from "@/request/api";
    export default {
        data() {
            return {
                swiperList: [],
                // 浏览器宽度
                bannerHeight: 0,
                screenWidth: 0,
                io: new IntersectionObserver((entries) => {
                    entries.forEach(item => {
                        // 当前元素可见时
                        if (item.isIntersecting) {
                            item.target.src = item.target.getAttribute('data-src') // 替换 src
                            io.unobserve(item.target) // 停止观察当前元素，避免不可见时再次调用 callback 函数
                        }
                    })
                })
            }
        },
        created() {
            // this.indexCarousel(0)
        },
        mounted() {
            this.screenWidth = window.innerWidth
            if (this.screenWidth < 1000) {
                this.bannerHeight = 520 / 1550 * this.screenWidth

                // 窗口大小发生改变
                window.onresize = () => {
                    this.screenWidth = window.innerWidth
                    this.bannerHeight = 520 / 1550 * this.screenWidth
                }
            } else {
                this.bannerHeight = 500 / 1550 * this.screenWidth

                // 窗口大小发生改变
                window.onresize = () => {
                    this.screenWidth = window.innerWidth
                    this.bannerHeight = 500 / 1550 * this.screenWidth
                }
            }


            // 首次加载时,需要调用一次
            this.screenWidth = window.innerWidth;
            this.setSize();
            // 窗口大小发生改变时,调用一次
            window.onresize = () => {
                this.screenWidth = window.innerWidth;
                this.setSize();
            };


            const imgs = document.querySelectorAll('[data-src]');
            // 监听所有图片元素
            imgs.forEach(item => {
                io.observe(item)
            })

        },
        methods: {
            setSize: function() {
                // if (this.screenWidth <= 1000) {
                //     this.indexCarousel(1)
                // } else {
                this.indexCarousel(0)
                    // 通过浏览器宽度(图片宽度)计算高度
                    // }
            },
            // 首页轮播
            indexCarousel(type) {
                const _this = this;
                let data = {
                    type: type //0pc 1手机
                };
                indexCarousel(data).then(res => {
                    _this.swiperList = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
        }
    }
</script>
<style scoped>
     :v-deep .el-carousel__item {
        /* height: 560px; */
        overflow: hidden;
        position: relative;
        width: 100%;
    }
    
    .el-carousel__item img {
        position: absolute;
        display: block;
        width: 100%;
        height: auto;
        /* height: 590px;
        min-width: 100%;
        width: auto;
        left: 50%;
        transform: translate(-50%, 0); */
    }
    
    .mobile-style {
        display: none;
    }
    
     ::v-deep .el-carousel__arrow {
        width: 50px;
        height: 50px;
    }
    
     ::v-deep .el-carousel__arrow i {
        font-size: 29px !important;
    }
    
    @media screen and (max-width: 1000px) {
        .pc-style {
            display: none;
        }
        .mobile-style {
            display: block;
        }
         :v-deep .el-carousel__item {
            /* height: 5.07rem; */
            overflow: hidden;
            position: relative;
            width: 100%;
        }
        .el-carousel__item img {
            position: absolute;
            display: block;
            /* max-height: 5.07rem; */
            width: 100%;
            height: auto;
            /* left: 50%; */
            /* transform: translate(-50%, 0); */
        }
    }
</style>